<template>
  <div class="manage-project-mortgage">
    <div class="container fluid">
      <div class="row justify-content-between mx-2 my-4">
        <h2 class="mb-1">Manage Bank Loans</h2>
        <mortgage-method-dropdown
          :bankLoanRoute="{ name: 'ManageProjectMortgageBankLoan' }"
          :bankLoanHistoryRoute="{
            name: 'ManageProjectMortgageBankLoanHistory'
          }"
          :otherRoute="{ name: 'ManageProjectMortgageOther' }"
        ></mortgage-method-dropdown>
      </div>

      <h4 class="p-2">Leads</h4>
      <lead-table
        detailRouteName="ManageProjectMortgageBankLoansDetail"
        :getBankLoans="mortgageAPI.getBankLoans"
      ></lead-table>
    </div>
  </div>
</template>

<script>
import LeadTable from "@/components/GlobalComponents/Shared/Banker/LeadTable";
import { projectMortgage as mortgageAPI } from "@/api";

export default {
  components: {
    LeadTable,
    MortgageMethodDropdown: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/MortgageMethodDropdown"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      mortgageAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {}
};
</script>

<style lang="scss"></style>
